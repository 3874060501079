<template>
  <div class="comment-list">
    <error :errors="articleError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet v-if="articlesComment.length > 0">
        <template v-slot:body>
          <div class="kt-pagination kt-pagination--brand">
            <div class="kt-pagination__links">
              <b-pagination
                class="mb-0"
                v-if="articlesCommentMeta.total"
                v-model="articlesCommentMeta.current_page"
                :total-rows="articlesCommentMeta.total"
                :per-page="articlesCommentMeta.per_page"
                aria-controls="articles"
                @change="onChangePage"
              />
            </div>
            <div class="kt-pagination__toolbar">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
              <b-button :to="{name: 'articles.list'}" class="btn btn-label-success btn-sm border-0">
                ئەسەر باشقۇرۇش
              </b-button>
            </div>
          </div>
        </template>
      </portlet>
      <portlet title="بارلىق ئىنكاسلار" fluidHeight>
        <template v-slot:toolbar>
          <b-form-radio-group
              id="btn-radios-filter"
              v-model="filter"
              :options="filterOptions"
              buttons
              @change="onChangeFilter"
              button-variant="primary"
              name="radios-btn-default"
          ></b-form-radio-group>
        </template>
        <template v-slot:body>
          <div v-if="articlesComment.length > 0" class="kt-widget3">
            <template v-for="comment in articlesComment">
              <media
                   :avatar="comment.commenter.avatar"
                   :nickname="comment.commenter.nickname"
                   :approval="comment.approved"
                   :content="comment.comment"
                   :time-ago="comment.time_ago"
                   :tips="comment.commentable ? `${comment.commentable.article.title}: ${comment.commentable.title}`:''"
              >
                <media v-if="comment.children_count > 0" v-for="children in comment.children" class="ml-5"
                   :avatar="children.commenter.avatar"
                   :nickname="children.commenter.nickname"
                   :approval="children.approved"
                   :content="children.comment"
                   :time-ago="children.time_ago"
                >
                  <template slot="status">
                    <b-button size="sm" @click="onClickApproved(children)" class="border-0" :class="children.approved ? 'btn-success' : 'btn-warning' ">{{ children.approved ? 'تەستىقلانغان' : 'تەستىقلانمىغان' }}</b-button>
                    <b-button size="sm" @click="deleteItem(children.id)" variant="warning" class="border-0 ml-1"><i class="flaticon-delete mr-0"></i></b-button>
                  </template>
                </media>
                <template slot="status">
                  <b-button size="sm" class="border-0" @click="onClickApproved(comment)" :class="comment.approved ? 'btn-success' : 'btn-warning' ">{{ comment.approved ? 'تەستىقلانغان' : 'تەستىقلانمىغان' }}</b-button>
                  <b-button size="sm" @click="deleteItem(comment.id, comment.children_count > 0)" variant="warning" class="border-0 ml-1"><i class="flaticon-delete mr-0"></i></b-button>
                </template>
              </media>
            </template>
          </div>
          <div v-else class="kt-callout__body">
            <div class="kt-callout__content">
              <p class="kt-callout__desc text-center mb-0">
                ھازىرچە مۇناسىۋەتلىك ئىنكاس ئۇچۇرى يوق ئىكەن.
              </p>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import articleMixin from "../../../../mixins/articleMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import media from "@/views/components/media";
  export default {
    name: "index",
    mixins: [ articleMixin, formBusyMixin ],
    components: { Error, Portlet, media },
    computed: {
      ...mapGetters(["articleError", "articlesComment", "articlesCommentMeta"])
    },
    created() {
      this.getArticlesComments();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەسەر باشقۇرۇش", route: "list" },
        { title: " ئىنكاس باشقۇرۇش" },
        { title: "بارلىق ئىنكاسلار" }
      ]);
    },
    data(){
      return {
        page: 1,
        filter: 0,
        formBusy: false,
        filterOptions: [
          { text: 'تەستىقلانمىغان', value: 1 },
          { text: 'ھەممىسى', value: 0 },
        ]
      };
    },
    methods: {
      onChangePage(page){
        this.page = page;
        this.getArticlesComments(page, this.filter);
      },
      refreshList(){
        this.getArticlesComments(1, this.filter);
      },
      onChangeFilter(filter){
        this.getArticlesComments(1, filter);
      },
      deleteItem(id, parent = false) {
        let message = parent ?
                'مەزكۇر ئىنكاسنى ئۆچۈرسىڭىز ئاستىدىكى باشقا ئىنكاسلارمۇ بىراقلا ئۆچۈرۈلىدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟':
                'ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟';
        this.$bvModal.msgBoxConfirm(message, {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteArticlesComment(id)
            .then(()=>{
              this.getArticlesComments(this.page, this.filter);
            })
        })
        .catch(err => {
          // An error occurred
        })
      },
      onClickApproved(item){
        console.log(item);
        item.approved = !item.approved;
        this.approveArticleComment(item.id);
      }
    }
  }
</script>
<style>
</style>
